import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';
import queryParser from '../utils/query';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  patchProductSuccess: {
    id: 'update-product-success',
    description: 'Updated product successfully',
    defaultMessage: 'Päivitys onnistui!',
  },

  patchProductFail: {
    id: 'update-product-fail',
    description: 'Updating product failed',
    defaultMessage: 'Päivitys epäonnistui! Yritä myöhemmin uudelleen.',
  },

  commentProductSuccess: {
    id: 'comment-product-success',
    description: 'Commented on product successfully',
    defaultMessage: 'Kommentointi onnistui!',
  },

  commentProductFail: {
    id: 'comment-product-fail',
    description: 'Commenting on product failed',
    defaultMessage: 'Kommentointi epäonnistui! Yritä myöhemmin uudelleen.',
  },

  setProductApproverSuccess: {
    id: 'set-product-approver-success',
    description: 'Product approver set successfully',
    defaultMessage:
      'Tuotteen tuotekelpoisuuden todentaja muutettu onnistuneesti!',
  },

  approveProductSuccess: {
    id: 'approve-product-success',
    description: 'Product was approved successfully',
    defaultMessage: 'Tuotteen "{name}" tuotekelpoisuus todettu!',
  },

  approveProductsSuccess: {
    id: 'approve-package-products-success',
    description: 'Products are approved successfully',
    defaultMessage: 'Tuotteiden tuotekelpoisuus todettu!',
  },

  disapproveProductSuccess: {
    id: 'disapprove-product-success',
    description: 'Product was disapproved successfully',
    defaultMessage: 'Tuotteen "{name}" tuotekelpoisuus peruttu!',
  },

  disapproveProductsSuccess: {
    id: 'disapprove-package-products-success',
    description: 'Products are disapproved successfully',
    defaultMessage: 'Tuotteiden tuotekelpoisuudet peruttu!',
  },

  setProductAprovableSuccess: {
    id: 'set-product-approvable-success',
    description: 'Product approvability set successfully',
    defaultMessage:
      'Tuotteen tuotekelpoisuuden todennettavuus muutettu onnistuneesti!',
  },

  approveProductNoRight: {
    id: 'approve-product-no-right',
    description: 'No rights to approve or disapprove products',
    defaultMessage: 'Sinulla ei ole oikeuksia todeta tuotekelpoisuuksia',
  },

  installProductSuccess: {
    id: 'install-product-success',
    description: 'Product was installed successfully',
    defaultMessage: 'Tuote "{name}" merkittiin asennetuksi!',
  },

  uninstallProductSuccess: {
    id: 'uninstall-product-success',
    description: 'Product was uninstalled successfully',
    defaultMessage: 'Tuotteen "{name}" asennusmerkintä poistettiin!',
  },

  installProductsSuccess: {
    id: 'install-products-success',
    description: 'Products are installed successfully',
    defaultMessage: 'Tuotteet merkittiin asennetuiksi!',
  },

  installProductPackageCompleted: {
    id: 'install-product-package-completed',
    description: 'Package is completed when trying to install product',
    defaultMessage:
      'Et voi muokata valmiin urakan tuotteiden tilaa!' +
      ' Kokeile päivittää sivu.',
  },

  installProductPackageArchived: {
    id: 'install-product-package-archived',
    description: 'Package is archived when trying to install product',
    defaultMessage:
      'Et voi muokata arkistoidun urakan tuotteita!' +
      ' Kokeile päivittää sivu.',
  },

  installProductNotFound: {
    id: 'install-product-not-found',
    description: 'Product not found while trying to install',
    defaultMessage: 'Virheellinen tuote! Kokeile päivittää sivu.',
  },

  installProductNoAccess: {
    id: 'install-product-no-access',
    description: 'User does not have the right to install products',
    defaultMessage:
      'Sinulla ei ole oikeuksia' +
      ' merkitä tuotteita asennetuksi tähän urakkaan!',
  },

  removeProductSuccess: {
    id: 'remove-product-success',
    description: 'Product was removed successfully',
    defaultMessage: 'Tuote "{name}" poistettiin!',
  },

  removeProductPackageCompleted: {
    id: 'remove-product-package-completed',
    description: 'Package is completed when trying to remove product',
    defaultMessage:
      'Et voi poistaa valmiista urakasta tuotteita!' +
      ' Kokeile päivittää sivu.',
  },

  removeProductPackageArchived: {
    id: 'remove-product-package-archved',
    description: 'Package is archived when trying to remove product',
    defaultMessage:
      'Et voi poistaa arkistoidun urakan tuotteita!' +
      ' Kokeile päivittää sivu.',
  },

  removeProductNotFound: {
    id: 'remove-product-not-found',
    description: 'Product not found while trying to remove',
    defaultMessage: 'Virheellinen tuote! Kokeile päivittää sivu.',
  },

  removeProductNoAccess: {
    id: 'remove-product-no-access',
    description: 'User does not have the right to remove products',
    defaultMessage:
      'Sinulla ei ole oikeuksia' + ' poistaa tämän urakan tuotteita!',
  },

  updateProductSuccess: {
    id: 'update-product-success',
    description: 'Product was updated successfully',
    defaultMessage: 'Tuotteen "{name}" dokumentaatio päivitettiin!',
  },

  updateProductPackageCompleted: {
    id: 'update-product-package-completed',
    description: 'Package is completed when trying to update product',
    defaultMessage:
      'Et voi muokata valmiin urakan tuotteita! Kokeile päivittää sivu.',
  },

  updateProductPackageArchived: {
    id: 'update-product-package-archived',
    description: 'Package is archived when trying to update product',
    defaultMessage:
      'Et voi muokata arkistoidun urakan tuotteita! Kokeile päivittää sivu.',
  },

  updateProductNotFound: {
    id: 'update-product-not-found',
    description: 'Product not found while trying to update',
    defaultMessage: 'Virheellinen tuote! Kokeile päivittää sivu.',
  },

  updateProductNoAccess: {
    id: 'update-product-no-access',
    description: 'User does not have the right to update products',
    defaultMessage:
      'Sinulla ei ole oikeuksia' + ' päivittää tämän urakan tuotteita!',
  },

  linkProductSuccess: {
    id: 'link-product-success',
    description: 'Product was linked successfully',
    defaultMessage: 'Tuotteet linkitettiin onnistuneesti!',
  },

  linkProductNotFound: {
    id: 'link-product-not-found',
    description: 'Product not found while trying to link',
    defaultMessage: 'Virheellinen tuote! Kokeile päivittää sivu.',
  },

  linkProductExternalNotFound: {
    id: 'link-product-external-not-found',
    description: 'External product not found while trying to link',
    defaultMessage: 'Virheellinen E21 tuote! Kokeile päivittää sivu.',
  },

  updateManualProductSuccess: {
    id: 'update-manual-product-success',
    description: 'Updating manual product was successful',
    defaultMessage: 'Tuote päivitetty!',
  },

  updateManualProductFail: {
    id: 'update-manual-product-fail',
    description: 'Updating manual product was unsuccessful',
    defaultMessage: 'Tuotteen päivittäminen epäonnistui!',
  },

  approveProductCarbonFootprintSuccess: {
    id: 'approve-product-carbon-footprint-success',
    description: 'Product carbon footprint was approved successfully',
    defaultMessage: 'Tuotteen "{name}" hiilijalanjälki todettu!',
  },

  disapproveProductCarbonFootprintSuccess: {
    id: 'disapprove-product-carbon-footprint-success',
    description: 'Product carbon footprint was disapproved successfully',
    defaultMessage: 'Tuotteen "{name}" hiilijalanjäljen todennus peruttu!',
  },
});

class ProductActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  clearProduct() {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_RECEIVE,
      product: null,
    });
  }

  setProductInformationModalOpen() {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_OPEN_INFORMATION_MODAL,
    });
  }

  getLocalProduct(id) {
    this._apiUtils
      .get(`/products/local/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  // Should this even be called for manual products?
  getExternalProduct(product) {
    if (!product.data || !product.data.id) {
      this._dispatcher.dispatch({
        type: ActionTypes.PRODUCT_RECEIVE_EXTERNAL_ERROR,
        error: null,
      });
      return;
    }
    const url = product.external_id
      ? `/products/${product.data.id}`
      : `/products/manual/${product.data.id}`;

    this._apiUtils
      .get(url)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE_EXTERNAL,
          product: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_EXTERNAL_ERROR,
              error,
            });
        }
      });
  }

  commentProduct(id, comment) {
    this._apiUtils
      .post(`/products/${id}/comment`, { id, comment })
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });
        this._dispatchMessage('success', messages.commentProductSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatchMessage('danger', messages.commentProductFail);
            console.error(error);
        }
      });
  }

  approveProductCarbonFootprint(id, gwpApproved) {
    const data = {
      id,
      gwpApproved,
    };

    this._apiUtils
      .patch(`/products/${id}/approve-carbon-footprint`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });

        if (gwpApproved) {
          this._dispatchMessage(
            'success',
            messages.approveProductCarbonFootprintSuccess,
            { name: response.body.data.name }
          );
        } else {
          this._dispatchMessage(
            'warning',
            messages.disapproveProductCarbonFootprintSuccess,
            { name: response.body.data.name }
          );
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.approveProductNoRight);
            break;

          default:
            console.error(error);
        }
      });
  }

  approveProduct(id) {
    const data = {
      id,
      approve: true,
    };

    this._apiUtils
      .patch(`/products/${id}/approve`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });

        this._dispatchMessage('success', messages.approveProductSuccess, {
          name: response.body.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        // These error messages seem appropriate enough for this action
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.approveProductNoRight);
            break;

          default:
            console.error(error);
        }
      });
  }

  approveProducts(ids) {
    this._dispatcher.dispatch(
      {
        type: ActionTypes.START_PROGRESS,
      },
      ids.forEach((id) => {
        const data = {
          id,
          approve: true,
        };

        this._apiUtils
          .patch(`/products/${id}/approve`, data)
          .then((response) => {
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE,
              product: response.body,
              count: ids.length,
            });
          })
          .catch((error) => {
            if (this._isAuthorizationError(error)) return;
            this._dispatcher.dispatch({
              type: ActionTypes.PROGRESS_FAILED,
            });
          });
      })
    );
  }

  disapproveProducts(ids) {
    this._dispatcher.dispatch(
      {
        type: ActionTypes.START_PROGRESS,
      },
      ids.forEach((id) => {
        const data = {
          id,
          approve: false,
        };

        this._apiUtils
          .patch(`/products/${id}/approve`, data)
          .then((response) => {
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE,
              product: response.body,
              count: ids.length,
            });
          })
          .catch((error) => {
            if (this._isAuthorizationError(error)) return;
            this._dispatcher.dispatch({
              type: ActionTypes.PROGRESS_FAILED,
            });
          });
      })
    );
  }

  disapproveProduct(id) {
    const data = {
      id,
      approve: false,
    };

    this._apiUtils
      .patch(`/products/${id}/approve`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });

        this._dispatchMessage('warning', messages.disapproveProductSuccess, {
          name: response.body.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        // These error messages seem appropriate enough for this action
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.approveProductNoRight);
            break;

          default:
            console.error(error);
        }
      });
  }

  setProductApprovable(id, state) {
    const data = {
      id,
      product_approvable: state,
    };

    this._apiUtils
      .patch(`/products/${id}/setProductApprovable`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_APPROVABLE_UPDATED,
          product: response.body,
        });
        this._dispatchMessage('success', messages.setProductAprovableSuccess, {
          name: response.body.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        // These error messages seem appropriate enough for this action
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.approveProductNoRight);
            break;

          default:
            console.error(error);
        }
      });
  }

  getUserApproverIds() {
    return this._apiUtils
      .get(`/products/me/approver-ids`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.APPROVER_IDS_RECEIVE,
          data: response.body,
        });
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        switch (error.message) {
          default:
            console.error(error);
        }
      });
  }

  setProductApprover(productId, username, email) {
    return this._apiUtils
      .patch(`/products/${productId}/approver`, { username, email })
      .then((response) => {
        this._dispatchMessage('success', messages.setProductApproverSuccess);
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );
            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.approveProductNoRight);
            break;

          default:
            this._dispatchMessage('danger', messages.patchProductFail);
            console.error(error);
        }
      });
  }

  getProductApprover(productId) {
    return this._apiUtils
      .get(`/products/${productId}/approver`)
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );
            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            break;

          default:
            this._dispatchMessage('danger', messages.patchProductFail);
            console.error(error);
        }
      });
  }

  addManualProduct(data) {
    return this._apiUtils
      .post(`/products/manual`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_MANUAL_ADDED,
          product: response.body.product,
        });
        return response.body.product;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
      });
  }

  patchProduct(id, data) {
    return this._apiUtils
      .patch(`/packages/products/${id}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });
        this._dispatchMessage('success', messages.patchProductSuccess);
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatchMessage('danger', messages.patchProductFail);
            console.error(error);
        }
      });
  }

  async updatePackageProductChemicalInformation(packageId, productId, data) {
    try {
      await this._apiUtils.patch(
        `/packages/${packageId}/products/${productId}/chemicalInfo`,
        data
      );

      // HACK: Applying the updated backend data to state seems way too difficult.
      // Thankfully everything seems to work even if the state is not updated :D

      this._dispatchMessage('success', messages.patchProductSuccess);
    } catch (error) {
      if (this._isAuthorizationError(error)) {
        return;
      }

      this._dispatchMessage('danger', messages.patchProductFail);
      console.error(error);
    }
  }

  uninstallProduct(product) {
    return this._apiUtils
      .patch(`/products/${product.id}/uninstall`, {})
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_UNINSTALLED,
          product: response.body,
        });
        this._dispatchMessage('success', messages.uninstallProductSuccess, {
          name: product.data ? product.data.name : '' || '',
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.installProductNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  installProduct(product) {
    const data = {
      id: product.id,
      status: 1,
    };

    return this._apiUtils
      .patch(`/products/${product.id}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_INSTALLED,
          product: response.body,
        });

        const snapshot = response.body.snapshot_data;

        this._dispatchMessage('success', messages.installProductSuccess, {
          name: (snapshot && snapshot.name) || '',
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.installProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.installProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.installProductNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  installProducts(ids) {
    this._dispatcher.dispatch(
      {
        type: ActionTypes.START_PROGRESS,
      },
      ids.forEach((id) => {
        const data = {
          id: id,
          status: 1,
        };

        this._apiUtils
          .patch(`/products/${id}`, data)
          .then((response) => {
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_INSTALLED,
              product: response.body,
              count: ids.length,
            });
          })
          .catch((error) => {
            if (this._isAuthorizationError(error)) return;
            this._dispatcher.dispatch({
              type: ActionTypes.PROGRESS_FAILED,
            });
          });
      })
    );
  }

  uninstallProducts(ids) {
    this._dispatcher.dispatch(
      {
        type: ActionTypes.START_PROGRESS,
      },
      ids.forEach((id) => {
        this._apiUtils
          .patch(`/products/${id}/uninstall`, {})
          .then((response) => {
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_UNINSTALLED,
              product: response.body,
              count: ids.length,
            });
          })
          .catch((error) => {
            if (this._isAuthorizationError(error)) return;
            this._dispatcher.dispatch({
              type: ActionTypes.PROGRESS_FAILED,
            });
          });
      })
    );
  }

  removeProduct(id, packageID) {
    this._apiUtils
      .delete(`/products/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_REMOVED,
          product: response.body.product,
          packageID,
        });

        this._dispatchMessage('success', messages.removeProductSuccess, {
          name: response.body.product.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.removeProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.removeProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.removeProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.removeProductNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  removeManualProduct(id) {
    this._apiUtils
      .delete(`/products/manual/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_MANUAL_REMOVED,
          product: response.body.product,
        });

        this._dispatchMessage('warning', messages.removeProductSuccess, {
          name: response.body.product.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
      });
  }

  updateProduct(id) {
    this._apiUtils
      .post(`/products/${id}/update`, null)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });

        this._dispatchMessage('success', messages.updateProductSuccess, {
          name: response.body.data.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.updateProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.updateProductPackageArchived
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.updateProductNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchMessage('danger', messages.updateProductNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  updateManualProduct(id, data) {
    this._apiUtils
      .patch(`/products/manual/${id}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body.product,
        });

        this._dispatchMessage('success', messages.updateManualProductSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatchMessage('danger', messages.updateManualProductFail);
            console.error(error);
        }
      });
  }

  async updateCompanyProductChemicalInfo(productId, data) {
    try {
      await this._apiUtils.patch(
        `/products/manual/${productId}/chemicalInfo`,
        data
      );

      // HACK: Applying the updated backend data to state seems way too difficult.
      // Thankfully everything seems to work even if the state is not updated :D

      this._dispatchMessage('success', messages.updateManualProductSuccess);
    } catch (error) {
      if (this._isAuthorizationError(error)) {
        return;
      }

      switch (error.message) {
        default:
          this._dispatchMessage('danger', messages.updateManualProductFail);
          console.error(error);
      }
    }
  }

  linkProduct(productID, externalID) {
    this._apiUtils
      .post(`/products/${productID}/link/${externalID}`, null)
      .then(() => {
        this._dispatchMessage('success', messages.linkProductSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.linkProductNotFound);

            break;

          case ErrorTypes.ERROR_PRODUCT_EXTERNAL_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.linkProductExternalNotFound
            );

            break;

          default:
            console.error(error);
        }
      });
  }

  getSearchProduct(packageID, product) {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_LOADING,
    });

    const uri = product.data.manual
      ? `/products/manual/${product.data.id}`
      : `/products/${product.data.id}`;

    this._apiUtils
      .get(uri)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: {
            ...product,
            data: response.body,
          },
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE_ERROR,
          error,
        });
      });
  }

  searchManualProducts(query, page) {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_GLOBAL_SEARCH_LOADING,
    });

    const params = {
      query,
      page,
    };

    const qs = queryParser.stringify(params);
    this._apiUtils
      .get(`/products/manual/search?${qs}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH,
          products: response.body.products,
          properties: null,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR,
            });
        }
      });
  }

  searchGlobalProducts(
    id,
    query,
    filters,
    page,
    includeDeleted,
    onlyFavourites,
    onlyCompanyProducts,
    fileFilters
  ) {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_GLOBAL_SEARCH_LOADING,
    });

    const params = {
      id,
      query: query.trim(),
      filters,
      page,
    };

    if (includeDeleted) params.includeDeleted = includeDeleted;
    if (onlyFavourites) params.onlyFavourites = onlyFavourites;
    if (onlyCompanyProducts) params.onlyCompanyProducts = onlyCompanyProducts;
    if (fileFilters) params.fileFilters = fileFilters;

    this._apiUtils
      .get(`/products?${queryParser.stringify(params)}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH,
          products: response.body.products,
          properties: response.body.properties,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR,
              error: error,
            });
        }
      });
  }

  clearGlobalProductSearch() {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH,
      products: null,
    });
  }

  getSafetyData(langCode = 'fi', change = false) {
    this._apiUtils
      .get(`/safetycodes/${langCode}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.SAFETY_DATA_RECEIVE,
          langCode,
          safetyData: response.body,
        });
        if (change) {
          this._dispatcher.dispatch({
            type: ActionTypes.CHANGE_LANGUAGE,
            langCode,
          });
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.SAFETY_DATA_RECEIVE_ERROR,
          langCode,
          error: error,
        });
      });
  }

  changeLanguage(langCode = 'fi') {
    this._dispatcher.dispatch({
      type: ActionTypes.CHANGE_LANGUAGE,
      langCode,
    });
  }

  searchPackageProducts(packageId, keywords, svhc) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_PRODUCT_SEARCH_LOADING,
    });

    this._apiUtils
      .get(
        `/packages/${packageId}/search-products?${queryParser.stringify({
          keywords,
          svhc,
        })}`
      )
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_PRODUCT_SEARCH_RECEIVE,
          result: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR,
              error: error,
            });
        }
      });
  }

  clearSearchPackageProductsResult() {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_PRODUCT_SEARCH_RECEIVE,
      result: null,
    });
  }
}

export default ProductActionCreators;
