import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

export function ApproverSelector({
  user,
  packageId,
  approvers,
  getApproverData,
  setApproverData,
  refetchApproverIds,
}) {
  const [loading, setLoading] = useState(false);
  const [approver, setApprover] = useState(null);
  const [error, setError] = useState(false);
  const [inheritedApprover, setInheritedApprover] = useState(null);

  const [selectedApprover, setSelectedApprover] = useState({
    value: '',
    label: 'Ei ole',
    email: null,
  });

  const emptyOption = {
    value: '',
    label: inheritedApprover
      ? `(${inheritedApprover.request_approval_username})`
      : `Ei ole`,
    email: null,
  };

  const approverRights =
    user?.admin === true || user?.role === 1 || user?.role === 0;

  let approverOptions =
    approvers
      ?.filter((a) => a.role_access || a.can_approve.includes(packageId))
      ?.map((right) => ({
        value: right.username,
        label: right.username,
        email: right.email,
      })) ?? [];

  approverOptions = [emptyOption, ...approverOptions];

  const initSelected = (data) => {
    if (data.approver) {
      setSelectedApprover({
        value: data.approver.request_approval_username,
        label: data.approver.request_approval_username,
        email: data.approver.request_approval_email,
      });
    } else if (data.inheritedApprover) {
      setSelectedApprover({
        value: '',
        label: `(${data.inheritedApprover.request_approval_username})`,
        email: null,
      });
    } else {
      setSelectedApprover({
        value: '',
        label: 'Ei ole',
        email: null,
      });
    }
  };

  useEffect(() => {
    const fetchApprovers = async () => {
      setLoading(true);
      if (approverRights && packageId) {
        const data = await getApproverData();

        if (data) {
          setApprover(data.approver);
          setInheritedApprover(data.inheritedApprover);
          initSelected(data);
        } else {
          setError(true);
        }
      }
      setLoading(false);
    };
    fetchApprovers();
  }, []);

  if (!approverRights || (!loading && error)) {
    return null;
  }

  const updateApprover = async () => {
    let data;
    if (!selectedApprover?.value) {
      data = await setApproverData(null, null);
    } else {
      data = await setApproverData(
        selectedApprover.value,
        selectedApprover.email
      );
    }
    setApprover(data.approver);
    setInheritedApprover(data.inheritedApprover);
    if (refetchApproverIds) {
      refetchApproverIds();
    }
  };

  return (
    <div>
      <hr />
      <FormattedMessage
        id='approver'
        description='Select approver'
        defaultMessage='Tuotekelpoisuuden toteamisen vastuuhenkilö'
      />
      {loading && (
        <div className='loading loading-green m-t'>
          <div></div>
        </div>
      )}
      {!loading && (
        <>
          <Select
            className='m-t m-b maxpanel'
            styles={{
              control: (styles) =>
                selectedApprover.value === '' && inheritedApprover
                  ? { ...styles, backgroundColor: '#eff8ff' }
                  : styles,
            }}
            value={selectedApprover}
            onChange={(value) => {
              setSelectedApprover({
                value: value.value,
                label: value.label,
                email: value.email,
              });
            }}
            backspaceRemovesValue={false}
            noOptionsMessage={() => 'Ei ole'}
            placeholder='Tuotekelpoisuudeen toteamisen vastuuhenkilö...'
            options={approverOptions}
          />
          <button
            type='button'
            className='btn btn-success'
            disabled={
              (approver === null && selectedApprover?.value === '') ||
              approver?.request_approval_username === selectedApprover?.value
            }
            onClick={updateApprover}
          >
            <FormattedMessage
              id='product-approver-submit'
              description='Submit product approver'
              defaultMessage='Tallenna vastuuhenkilö'
            />
          </button>
        </>
      )}
    </div>
  );
}
